<template>
	<div class="staff">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>スタッフ紹介</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									スタッフ紹介
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3 mb-4">
						<div class="profile">
							<img src="/img/staff/takazawa.jpg" class="img-fluid" />
							<div class="caption">
								<h6>
									General Manager
								</h6>
								<h4>Takazawa</h4>
							</div>
							<div class="caption2">
								<h4><small>General Manager</small><br />Takazawa</h4>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 mb-4">
						<div class="profile">
							<img src="/img/staff/mori.jpg" class="img-fluid" />
							<div class="caption">
								<h6>
									Technical Manager
								</h6>
								<h4>Mori</h4>
							</div>
							<div class="caption2">
								<h4><small>Technical Manager</small><br />Mori</h4>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 mb-4">
						<div class="profile">
							<img src="/img/staff/tsujii.jpg" class="img-fluid" />
							<div class="caption">
								<h6>
									Chief Mechanic
								</h6>
								<h4>Tsujii</h4>
							</div>
							<div class="caption2">
								<h4><small>Chief Mechanic</small><br />Tsujii</h4>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 mb-4">
						<div class="profile">
							<img src="/img/staff/ussy.jpg" class="img-fluid" />
							<div class="caption">
								<h6>
									Front
								</h6>
								<h4>Ussy</h4>
							</div>
							<div class="caption2">
								<h4><small>Front</small><br />Ussy</h4>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-lg-3 mb-4">
						<div class="profile">
							<img src="/img/staff/nomura.jpg" class="img-fluid" />
							<div class="caption">
								<h6>
									Mechanic
								</h6>
								<h4>Nomura</h4>
							</div>
							<div class="caption2">
								<h4><small>Mechanic</small><br />Nomura</h4>
							</div>
						</div>
					</div>

					<!-- Blog Sidebar Area -->
					<!--<div class="col-12 col-md-4">
						<Sidebar></Sidebar>
					</div>-->
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
//import Sidebar from "@/components/Sidebar.vue";

export default {
	title: "スタッフ紹介",
	components: {
		//Sidebar,
	},
};
</script>

<style scoped lang="scss">
.profile {
	position: relative;
	h4 {
		color: #fff;
		font-size: 1.4rem;
		small {
			font-size: 0.9rem;
		}
	}
	h6 {
		color: #fff;
		font-size: 0.9rem;
	}
	.caption {
		width: 100%;
		height: 100%;
		padding: 3rem 1rem 1rem 1rem;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 1;
		background: linear-gradient(to right, #1976d2, #19acd2);
		-webkit-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.caption2 {
		padding: 1rem;
		text-align: center;
		position: absolute;
		bottom: 0;
		opacity: 0;
		h4,
		h6 {
			margin: 0;
			padding: 0.2rem 5px;
			background-color: #111;
		}
	}
	&:hover {
		.caption {
			opacity: 0;
		}
		.caption2 {
			opacity: 1;
		}
	}
}
</style>
